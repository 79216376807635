.App {
    text-align: center;
    height: 100vh;
    width: 100vw;
    color: white;
    position: relative;
    scroll-snap-type: x mandatory;
    display: flex;
    overflow: scroll;
}

.main {
    scroll-snap-align: center;
    min-height: 100%;
    min-width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    font-size: calc(10px + 2vmin);
}

.main-split {
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 780px) {
    .main {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
}
