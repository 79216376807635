.weather-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
}

.weather-row {
    display: flex;
    justify-content: space-between;
    width: 50%;
    max-width: 380px;
    margin: 1.5rem 0;
    min-height: 2rem;
    height: 2rem;
}

.weather-row img {
    height: 2rem;
}

@media screen and (max-width: 780px) {
    .weather-row {
        margin: 1rem 0;
    }
}
