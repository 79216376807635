.metro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.metro-row {
    margin: 1.5rem 0;
    min-height: 2rem;
    height: 2rem;
}

@media screen and (max-width: 780px) {
    .metro-row {
        margin: 1rem 0;
    }
}
