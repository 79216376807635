.watering-can-button {
    background: none;
    padding: 0;
    border: none;
    transition: ease 0.2s;
    outline: none;
    width: 75%;
    max-width: 512px;
}

.watering-can-button:hover {
    cursor: pointer;
    transform: rotate(10deg);
}

.watering-can-button:active {
    transform: rotate(15deg);
}
